import React from 'react';
import './Tcf.css';

function Tcf() {
  return (
    <div className="tcf-container">
      <h1>Treating Customers Fairly Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to our Treating Customers Fairly Policy page. At Vanir, we are committed to providing our customers with exceptional service and products. This policy outlines our approach to ensuring that fairness is at the heart of our business operations. As a firm regulated by the Financial Conduct Authority (FCA), we adhere to stringent guidelines to promote transparency, honesty, and integrity in all our interactions.
        </p>
      </section>

      <section>
        <h2>Policy Details</h2>
      </section>

      <section>
        <h3>Culture and Strategy</h3>
        <p>
          At Vanir, we believe that treating customers fairly is integral to our corporate culture. Our management team leads by example, embedding this principle into every aspect of our operations. We align our business strategies with our core value of customer fairness, ensuring that our decisions always prioritize our customers’ best interests.
        </p>
      </section>

      <section>
        <h3>Employee Responsibilities</h3>
        <p>
          Every team member at Vanir is expected to uphold our commitment to fairness. Employees are familiarized with this policy and are encouraged to conduct themselves in a manner that reflects our dedication to our customers’ fair treatment.
        </p>
      </section>

      <section>
        <h3>Outcomes</h3>
        <p>Our goal is to ensure that:</p>
        <ul>
          <li>Customers feel confident in their dealings with us, knowing that fairness is central to our culture.</li>
          <li>Our products and services are designed and marketed to meet the needs of our customers.</li>
          <li>Customers receive clear, comprehensive information at all stages of their interaction with us.</li>
          <li>We provide advice that is in the best interest of the customer, considering their individual circumstances.</li>
          <li>Our products and services perform as expected, meeting the standards we have communicated.</li>
          <li>Customers face no unreasonable barriers when they wish to switch providers, make a claim, or lodge a complaint.</li>
        </ul>
      </section>

      <section>
        <h3>Management Information and Conflicts of Interest</h3>
        <p>
          We regularly assess the effectiveness of our policies through robust management information systems. Identifying and addressing any conflicts of interest is a priority to ensure that our customers’ interests are always protected.
        </p>
      </section>

      <section>
        <h3>Vulnerable Customers</h3>
        <p>
          We pay special attention to the needs of vulnerable customers, ensuring they receive the support and consideration necessary to make informed decisions.
        </p>
      </section>

      <section>
        <h3>Recruitment, Remuneration, and Training</h3>
        <p>
          Our recruitment and training practices emphasize the importance of fair treatment of customers. We ensure that our remuneration policies do not incentivize practices that could conflict with the interest of fair customer treatment.
        </p>
      </section>

      <section>
        <h3>Customer Communications</h3>
        <p>
          We communicate transparently and clearly with our customers, ensuring they understand our terms, services, and any information they need to make informed decisions.
        </p>
      </section>

      <section>
        <h3>Complaints and Breaches</h3>
        <p>
          Our complaints process is straightforward and fair, ensuring that any concerns are addressed promptly and effectively. We maintain a rigorous process to record and rectify any breaches of our policy.
        </p>
      </section>

      <section>
        <h3>Contact Information</h3>
        <p>
          If you have any questions or need further information about our Treating Customers Fairly Policy, please contact us at <a href="mailto:sales@vanir.co.uk">sales@vanir.co.uk</a>
        </p>
      </section>
    </div>
  );
}

export default Tcf;
