import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NewTrucks.css';

function NewTrucks() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  return (
    <div className="new-trucks-page">
      {/* Hero Section */}
      <section className="new-trucks-hero">
        <h2>New Trucks</h2>
        <p>Drive your new truck away today</p>
        <form className="new-trucks-search-form">
          <select>
            <option>Make</option>
            <option>Scania</option>
            <option>DAF</option>
            <option>Mercedes-Benz</option>
          </select>
          <select>
            <option>Model</option>
            <option>Any</option>
            <option>Scania R Series</option>
            <option>Mercedes-Benz Actros</option>
          </select>
          <input type="text" placeholder="Postcode" />
          <select>
            <option>Distance</option>
            <option>Local</option>
            <option>National</option>
          </select>
          <button className="cta-button">Search 4,934 trucks</button>
        </form>
      </section>

      {/* Promotion Banner */}
      <section className="promotion-banner">
        <h2>Brand new trucks found</h2>
        <p>Available now and ready to roll.</p>
      </section>

      {/* Truck Categories */}
      <section className="truck-categories">
        <h2>Find a truck to suit you</h2>
        <div className="categories-grid">
          <div className="category-card">Rigid Trucks</div>
          <div className="category-card">Tractor Units</div>
          <div className="category-card">Trailers</div>
        </div>
      </section>

      {/* Brands Section */}
      <section className="truck-brands">
        <h2>Browse by brand</h2>
        <div className="brands-grid">
          <div className="brand-card">
            <img src={require('../assets/logos/scania-logo.jpg')} alt="Scania Logo" />
            <span>Scania</span>
          </div>
          <div className="brand-card">
            <img src={require('../assets/logos/daf-logo.jpg')} alt="DAF Logo" />
            <span>DAF</span>
          </div>
          <div className="brand-card">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-card">
            <img src={require('../assets/logos/volvo-logo.jpg')} alt="Volvo Logo" />
            <span>Volvo</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-card">
                <img src={require('../assets/logos/isuzu-logo.jpg')} alt="Isuzu Logo" />
                <span>Isuzu</span>
              </div>
              <div className="brand-card">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="brand-card">
                <img src={require('../assets/logos/man-logo.jpg')} alt="MAN Logo" />
                <span>MAN</span>
              </div>
              <div className="brand-card">
                <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
                <span>Renault</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Stock Summary */}
      <section className="stock-summary">
        <div className="summary-content">
          <h2>In stock and available now</h2>
          <p>There's no need to wait months for your new truck to be built and shipped to you.</p>
          <button className="cta-button">See what's in stock</button>
        </div>
        <img src="/path/to/truck-image.jpg" alt="New Truck" className="summary-image" />
      </section>

      {/* Benefits Section */}
      <section className="truck-benefits">
        <h2>New trucks come with great benefits</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <h3>Full Manufacturer Warranty</h3>
            <p>New trucks come with full manufacturer warranty, so in the unlikely event anything goes wrong - you're covered.</p>
          </div>
          <div className="benefit-card">
            <h3>Finance Options</h3>
            <p>Most new trucks are available to buy on finance, to help you break your costs down into affordable monthly payments.</p>
          </div>
          <div className="benefit-card">
            <h3>Latest Technology</h3>
            <p>New trucks come equipped with the latest technology and fuel efficiency systems, to help you keep costs down in the long run.</p>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about-us-summary">
        <h2>Celebrating over 40 years in the industry</h2>
        <p>Since 1977, we've helped millions of people find their perfect truck. Together with manufacturers and retailers, we constantly strive to make truck buying easier.</p>
        <p>Trustpilot Rating: 4.7/5 based on 95,106 reviews.</p>
        <Link to="/about" className="about-us-link">About Vanir Motors</Link>
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default NewTrucks;
