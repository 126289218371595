import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  const [showAllBrands, setShowAllBrands] = useState(false);  // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands);  // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "Where is the best place to buy a car?", answer: "You can buy a car from dealerships, online marketplaces, or directly from individuals.", isOpen: false },
    { question: "How do I check my credit score?", answer: "You can check your credit score through various online services or financial institutions.", isOpen: false },
    { question: "What is car finance?", answer: "Car finance is a way to spread the cost of a car over a period of time.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  
  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h2>Find your next car</h2>
          <form className="search-form">
            <select>
              <option>Make</option>
              <option>BMW</option>
              <option>Audi</option>
              {/* Add more options */}
            </select>
            <select>
              <option>Model</option>
              <option>3 Series</option>
              <option>A4</option>
              {/* Add more options */}
            </select>
            <button className="cta-button">View Deals</button>
          </form>
        </div>
      </section>

      {/* Leasing Offers Section */}
      <section className="leasing-offers">
        <h2>Lease a brand new car</h2>
        <div className="offers-carousel">
          {/* Add individual offers as divs */}
          <div className="offer-card">Offer 1</div>
          <div className="offer-card">Offer 2</div>
          <div className="offer-card">Offer 3</div>
          {/* More offers */}
        </div>
        <Link to="/offers" className="view-all">View more deals</Link>
      </section>

      {/* Browse by Brand Section */}
      <section className="brands">
        <h2>Browse by brand</h2>
        <div className="brand-grid">
        <div className="brand-logo">
            <img src={require('../assets/logos/audi-logo.jpg')} alt="Audi Logo" />
            <span>Audi</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/bmw-logo.jpg')} alt="BMW Logo" />
            <span>BMW</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          {/* Add more brands */}
          {showAllBrands && (
            <>
              <div className="brand-logo">
                <img src={require('../assets/logos/abarth-logo.jpg')} alt="Abarth Logo" />
                <span>Abarth</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/alfaRomeo-logo.jpg')} alt="Alfa Romeo Logo" />
                <span>Alfa Romeo</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/citroen-logo.jpg')} alt="Citroen Logo" />
                <span>Citroen</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/fiat-logo.jpg')} alt="Fiat Logo" />
                <span>Fiat</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/honda-logo.jpg')} alt="Honda Logo" />
                <span>Honda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/hyundai-logo.jpg')} alt="Hyundai Logo" />
                <span>Hyundai</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/jaguar-logo.jpg')} alt="Jaguar Logo" />
                <span>Jaguar</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/jeep-logo.jpg')} alt="Jeep Logo" />
                <span>Jeep</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/kia-logo.jpg')} alt="Kia Logo" />
                <span>Kia</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/landRover-logo.jpg')} alt="Land Rover Logo" />
                <span>Land Rover</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/lexus-logo.jpg')} alt="Lexus Logo" />
                <span>Lexus</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/maxus-logo.jpg')} alt="Maxus Logo" />
                <span>Maxus</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/mazda-logo.jpg')} alt="Mazda Logo" />
                <span>Mazda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/mg-logo.jpg')} alt="MG Logo" />
                <span>MG</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/mini-logo.jpg')} alt="Mini Logo" />
                <span>Mini</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/mitsubishi-logo.jpg')} alt="Mitsubishi Logo" />
                <span>Mitsubishi</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/polestar-logo.jpg')} alt="Polestar Logo" />
                <span>Polestar</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/porsche-logo.jpg')} alt="Porsche Logo" />
                <span>Porsche</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
                <span>Renault</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/seat-logo.jpg')} alt="Seat Logo" />
                <span>Seat</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/skoda-logo.jpg')} alt="Skoda Logo" />
                <span>Skoda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/smart-logo.jpg')} alt="Smart Logo" />
                <span>Smart</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/subaru-logo.jpg')} alt="Subaru Logo" />
                <span>Subaru</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/suzuki-logo.jpg')} alt="Suzuki Logo" />
                <span>Suzuki</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/tesla-logo.jpg')} alt="Tesla Logo" />
                <span>Tesla</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/vauxhall-logo.jpg')} alt="Vauxhall Logo" />
                <span>Vauxhall</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
                <span>Volkswagen</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/volvo-logo.jpg')} alt="Volvo Logo" />
                <span>Volvo</span>
              </div>
              {/* Add more brand logos here */}
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Buying Essentials */}
      <section className="buying-essentials">
        <h2>Buying essentials</h2>
        <div className="essentials-grid">
          <div className="essential-card">What is HP?</div>
          <div className="essential-card">Request Advice</div>
          <div className="essential-card">Car Finance Guide</div>
          <div className="essential-card">Check a car's history</div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>
    </div>
  );
}

export default HomePage;
