import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import './ContactPage.css';

function ContactPage() {
  return (
    <div className="contact-page">
      {/* Hero Section */}
      <section className="contact-hero">
        <h2>Get help or contact us</h2>
        <p>
          Check out our guides and FAQs on our help centre. If you still have questions, <Link to="/chat">chat with us</Link>. If you have a complaint <Link to="/complaint">get in touch</Link>.
        </p>
        <button className="cta-button">Visit our help centre</button>
      </section>

      {/* Contact Cards Section */}
      <section className="contact-cards">
        <div className="contact-card">
          <FaPhoneAlt className="contact-icon" />
          <h3>Phone</h3>
          <p>Call us at <a href="tel:+441516731916">0151 673 1916</a></p>
        </div>
        <div className="contact-card">
          <FaEnvelope className="contact-icon" />
          <h3>Email</h3>
          <p>Email us at <a href="mailto:sales@vanir.co.uk">sales@vanir.co.uk</a></p>
        </div>
        <div className="contact-card">
          <FaWhatsapp className="contact-icon" />
          <h3>WhatsApp</h3>
          <p>Chat with us on <a href="https://wa.me/447496004554" target="_blank" rel="noopener noreferrer">WhatsApp</a></p>
        </div>
      </section>

      {/* Buying Guides */}
      <section className="buying-guides">
        <h2>Buying guides</h2>
        <div className="guides-grid">
          <div className="guide-card">
            <h3>Buying a car online</h3>
            <p>Have a look at our top tips to buy a car online in total confidence.</p>
          </div>
          <div className="guide-card">
            <h3>Safety advice</h3>
            <p>Learn how to have a safe and pleasant buying experience.</p>
          </div>
          <div className="guide-card">
            <h3>Test driving a used car</h3>
            <p>Know what to look out for when test driving a used car.</p>
          </div>
        </div>
      </section>

      {/* Selling Guides */}
      <section className="selling-guides">
        <h2>Selling guides</h2>
        <div className="guides-grid">
          <div className="guide-card">
            <h3>How to sell a car</h3>
            <p>View our guide that will walk you through selling your car.</p>
          </div>
          <div className="guide-card">
            <h3>Advertising prices</h3>
            <p>Explore our packages to find one that suits you.</p>
          </div>
          <div className="guide-card">
            <h3>Meeting a potential buyer</h3>
            <p>Check out our pointers for meeting a potential buyer.</p>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        <div className="faq-item">How do I unsubscribe from emails?</div>
        <div className="faq-item">When will my advert be live?</div>
        <div className="faq-item">How do I delete my advert?</div>
        <div className="faq-item">Why can't I edit or remove my review?</div>
        <div className="faq-item">Where is the review I posted?</div>
        <p>
          Search more questions on our <Link to="/support">help and support page</Link>.
        </p>
      </section>

      {/* Contact Methods */}
      <section className="contact-methods">
        <h2>Still need help?</h2>
        <div className="methods-grid">
          <div className="method-card">
            <h3>Chat with us</h3>
            <p>The fastest way to get a response.</p>
          </div>
          <div className="method-card">
            <h3>Call us</h3>
            <p>Lines open: Mon-Fri 9am to 5pm</p>
          </div>
          <div className="method-card">
            <h3>Email us</h3>
            <p>We will reply as soon as we can. Usually within 3 business days.</p>
          </div>
        </div>
      </section>

      {/* Disclaimer */}
      <section className="disclaimer">
        <p>
          Vanir is committed to treating all of our customers fairly. For more information, <Link to="/consumer-policy">read our Consumer Policy</Link>.
        </p>
      </section>
    </div>
  );
}

export default ContactPage;
