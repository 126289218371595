import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
  const location = useLocation();
  const [menuItems, setMenuItems] = useState(getMenuItems(location.pathname));

  // Function to determine menu items based on path
  function getMenuItems(path) {
    if (path.startsWith('/cars') || path === '/') {
      return [
        { name: 'Used Cars', path: '/cars/used' },
        { name: 'New Cars', path: '/cars/new' },
        { name: 'Car Finance', path: '/cars/finance' },
        { name: 'Car Leasing', path: '/cars/leasing' },
      ];
    } else if (path.startsWith('/vans')) {
      return [
        { name: 'Used Vans', path: '/vans/used' },
        { name: 'New Vans', path: '/vans/new' },
        { name: 'Van Finance', path: '/vans/finance' },
        { name: 'Van Leasing', path: '/vans/leasing' },
      ];
    } else if (path.startsWith('/trucks')) {
      return [
        { name: 'Used Trucks', path: '/trucks/used' },
        { name: 'New Trucks', path: '/trucks/new' },
        { name: 'Sell Your Truck', path: 'https://vanbuyersuk.com' },
      ];
    }
    // Default to Cars menu for other paths
    return [
      { name: 'Used Cars', path: '/cars/used' },
      { name: 'New Cars', path: '/cars/new' },
      { name: 'Car Finance', path: '/cars/finance' },
      { name: 'Car Leasing', path: '/cars/leasing' },
    ];
  }

  // Update menu items when location changes
  React.useEffect(() => {
    setMenuItems(getMenuItems(location.pathname));
  }, [location]);

  return (
    <header>
      <div className="top-bar">
        <div className="logo">
          <Link to="/">Vanir Motors</Link>
        </div>
        <nav className="main-nav">
          <ul className="vehicle-types">
            <li><Link to="/cars">Cars</Link></li>
            <li><Link to="/vans">Vans</Link></li>
            <li><Link to="/trucks">Trucks</Link></li>
          </ul>
          <ul className="right-menu">
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </div>
      <nav className="sub-menu">
        <ul className="menu-items">
          {menuItems.map((item) => (
            <li key={item.path}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
