import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Cars.css';

function Cars() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  return (
    <div className="cars-page">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h2>Car finance</h2>
          <p>See cars available on finance near you</p>
          <form className="finance-search-form">
            <input type="text" placeholder="Postcode" />
            <input type="number" placeholder="Distance" />
            <button className="cta-button">Search deals</button>
          </form>
          <p className="learn-more">
            <Link to="/finance-info">How much can I afford?</Link>
          </p>
        </div>
      </section>

      {/* Popular Brands Section */}
      <section className="brands">
        <h2>View finance deals from popular brands</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/audi-logo.jpg')} alt="Audi Logo" />
            <span>Audi</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/bmw-logo.jpg')} alt="BMW Logo" />
                <span>BMW</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
                <span>Renault</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/honda-logo.jpg')} alt="Honda Logo" />
                <span>Honda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/kia-logo.jpg')} alt="Kia Logo" />
                <span>Kia</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* How Finance Works Section */}
      <section className="finance-info">
        <h2>How finance works on Vanir Motors</h2>
        <p>
          Simply input your location to see vehicles available on finance or explore our various finance options.
        </p>
        <p>
          We offer a broad selection of vehicles with various finance options tailored to suit your needs.
        </p>
        <Link to="/finance-options" className="cta-button">Learn more about financing</Link>
      </section>

      {/* Monthly Prices Section */}
      <section className="monthly-prices">
        <div className="info-box">
          <h3>See monthly prices upfront</h3>
          <p>
            You can find vehicles on PCP, HP, or CS finance based on your preferences.
          </p>
          <Link to="/finance-prices" className="cta-button">Learn more</Link>
        </div>
      </section>

      {/* Footer Guides Section */}
      <section className="guides">
        <h2>Car finance guides</h2>
        <div className="guides-grid">
          <div className="guide-card">Tips on applying for car finance</div>
          <div className="guide-card">What to do if you have a poor credit score</div>
          <div className="guide-card">Which type of finance is right for you</div>
          <div className="guide-card">Buying a car on finance</div>
        </div>
      </section>
    </div>
  );
}

export default Cars;
