import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UsedCars.css';

function UsedCars() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  return (
    <div className="used-cars-page">
      {/* Hero Section */}
      <section className="used-cars-hero">
        <h2>Used Cars</h2>
        <p>The UK's largest choice of pre-loved cars</p>
        <form className="used-cars-search-form">
          <select>
            <option>Make</option>
            <option>Audi</option>
            <option>BMW</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Model</option>
            <option>A4</option>
            <option>3 Series</option>
            {/* Add more options */}
          </select>
          <input type="text" placeholder="Postcode" />
          <button className="cta-button">Search 440,021 cars</button>
        </form>
      </section>

      {/* Peace of Mind Section */}
      <section className="peace-of-mind">
        <div className="info-box">
          <h3>Play the right price</h3>
          <p>Use our car pricing tool to get the best deals.</p>
        </div>
        <div className="info-box">
          <h3>Car history checks</h3>
          <p>Sellers you can trust with verified history.</p>
        </div>
      </section>

      {/* Browse by Brand Section */}
      <section className="browse-brands">
        <h2>Browse by brand</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/audi-logo.jpg')} alt="Audi Logo" />
            <span>Audi</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/bmw-logo.jpg')} alt="BMW Logo" />
                <span>BMW</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
                <span>Renault</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/honda-logo.jpg')} alt="Honda Logo" />
                <span>Honda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/kia-logo.jpg')} alt="Kia Logo" />
                <span>Kia</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Part-Exchange Section */}
      <section className="part-exchange">
        <div className="info-box">
          <h3>Have a car to part-exchange?</h3>
          <p>Get a valuation and use it towards your next purchase.</p>
          <Link to="/part-exchange" className="cta-button">Get a valuation</Link>
        </div>
        <div className="info-box">
          <h3>Join the millions who value their car with Vanir Motors</h3>
          <p>Use our car valuation tool to get a fair price.</p>
          <Link to="/valuation" className="cta-button">Value my car</Link>
        </div>
      </section>

      {/* Popular Models Section */}
      <section className="popular-models">
        <h2>Popular models in the UK</h2>
        <div className="model-grid">
          <div className="model-card">Ford Fiesta</div>
          <div className="model-card">BMW 3 Series</div>
          <div className="model-card">Mercedes-Benz E-Class</div>
          <div className="model-card">Audi A4</div>
        </div>
      </section>

      {/* Guides Section */}
      <section className="guides">
        <h2>Guides to buying a used car</h2>
        <div className="guide-grid">
          <div className="guide-card">Check car history</div>
          <div className="guide-card">Inspect a used car</div>
          <div className="guide-card">Negotiate the best price</div>
          <div className="guide-card">Arrange test drives</div>
        </div>
      </section>

      {/* Stock Section */}
      <section className="stock-info">
        <h2>440,021 pre-owned cars ready and waiting</h2>
        <Link to="/browse-stock" className="cta-button">Browse stock</Link>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        <div className="faq-item">What is the best time to buy a used car?</div>
        <div className="faq-item">Are used cars reliable?</div>
        <div className="faq-item">How do I inspect a used car?</div>
        <div className="faq-item">Can I part-exchange my vehicle?</div>
        {/* Add more FAQs */}
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default UsedCars;
