import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Vans.css';

function Vans() {
  const [showAllBrands, setShowAllBrands] = useState(false);  // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands);  // Toggle visibility
  };

  const [faq, setFaq] = useState([
    { question: "What should I check when buying a used van?", answer: "When buying a used van, inspect both the exterior and interior thoroughly. Look for signs of rust, body damage, and any wear on the seats or controls. Check the van's service history, mileage, and previous ownership details. Ensure that important parts like the brakes, tires, suspension, and engine are in good condition. Additionally, verify the van's MOT and emissions status, and always request a professional mechanic’s inspection if you’re unsure.", isOpen: false },
    { question: "How do I find the best deals on used vans?", answer: "To find the best deals on used vans, compare listings from multiple reputable sources, such as online marketplaces, dealership websites, and local classified ads. Check for special sales, discounts, or financing deals that may be available on certain models. Be open to flexible timing; sometimes the best deals appear during end-of-quarter sales events or holiday promotions. Also, setting up alerts for price drops on specific van models can help you snag a deal faster.", isOpen: false },
    { question: "Can I trade in my old van for a new one?", answer: "Yes, many dealerships and vehicle marketplaces offer trade-in options. Trading in your old van can reduce the cost of your new purchase, as its trade-in value will be deducted from the price. To maximize your trade-in offer, clean and service your van, gather all maintenance records, and research its current market value. Be ready to negotiate the trade-in price as part of your overall deal.", isOpen: false },
    { question: "What financing options are available for vans?", answer: "Financing options for vans vary but typically include personal loans, hire purchase (HP), leasing, and personal contract purchase (PCP). In a hire purchase, you pay a deposit and monthly payments until you own the van. Leasing often allows for lower monthly payments and includes maintenance costs, though you don’t own the van. PCP financing offers a flexible balloon payment at the end of the term, allowing you to return, keep, or trade in the van. Check interest rates, loan terms, and eligibility before deciding, as these can affect overall costs.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };

  return (
    <div className="vans-page">
      {/* Hero Section */}
      <section className="vans-hero">
        <h2>Used Vans</h2>
        <p>The UK's largest choice of used vans</p>
        <form className="vans-search-form">
          <select>
            <option>Make</option>
            <option>Ford</option>
            <option>Volkswagen</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Model</option>
            <option>Transit</option>
            <option>Transporter</option>
            {/* Add more options */}
          </select>
          <input type="text" placeholder="Postcode" />
          <button className="cta-button">Search used vans</button>
        </form>
      </section>

      {/* Peace of Mind Section */}
      <section className="peace-of-mind">
        <div className="info-box">
          <h3>Find the right price</h3>
          <p>Use our pricing tools to get the best deals on used vans.</p>
        </div>
        <div className="info-box">
          <h3>Trusted van sellers</h3>
          <p>Purchase from sellers with verified history and transparency.</p>
        </div>
      </section>

      {/* Browse by Brand Section */}
      <section className="browse-brands">
        <h2>Browse vans by brand</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
            <span>Renault</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/citroen-logo.jpg')} alt="Citroen Logo" />
                <span>Citroen</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/fiat-logo.jpg')} alt="Fiat Logo" />
                <span>Fiat</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/man-logo.jpg')} alt="Man Logo" />
                <span>Man</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/maxus-logo.jpg')} alt="Maxus Logo" />
                <span>Maxus</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="toyota Logo" />
                <span>toyota</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/vauxhall-logo.jpg')} alt="Vauxhall Logo" />
                <span>Vauxhall</span>
              </div>
              
              {/* Add more brands as needed */}
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Part-Exchange Section */}
      <section className="part-exchange">
        <div className="info-box">
          <h3>Trade in your old van</h3>
          <p>Use our valuation tool and get a quote towards your next purchase.</p>
          <Link to="/part-exchange" className="cta-button">Get a valuation</Link>
        </div>
        <div className="info-box">
          <h3>Van valuation for a fair price</h3>
          <p>Join the many who trust us for accurate van valuations.</p>
          <Link to="/valuation" className="cta-button">Value my van</Link>
        </div>
      </section>

      {/* Popular Van Models Section */}
      <section className="popular-vans">
        <h2>Popular van models in the UK</h2>
        <div className="model-grid">
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/ford-transit.jpg')} alt="Ford Transit" className="model-image" />
            <span className="model-title">Ford Transit</span>
          </div>
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/volkswagen-crafter.jpg')} alt="Volkswagen Crafter" className="model-image" />
            <span className="model-title">Volkswagen Crafter</span>
          </div>
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/mercedes-sprinter.jpg')} alt="Mercedes-Benz Sprinter" className="model-image" />
            <span className="model-title">Mercedes-Benz Sprinter</span>
          </div>
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/renault-master.jpg')} alt="Renault Master" className="model-image" />
            <span className="model-title">Renault Master</span>
          </div>
        </div>
      </section>

      {/* Guides Section */}
      <section className="guides">
        <h2>Guides to buying a used van</h2>
        <div className="guide-grid">
          <div className="guide-card">Inspecting a used van</div>
          <div className="guide-card">Van financing options</div>
          <div className="guide-card">Negotiating a great deal</div>
          <div className="guide-card">Arranging test drives</div>
        </div>
      </section>

      {/* Stock Section */}
      <section className="stock-info">
        <h2>Thousands of vans ready to drive away</h2>
        <Link to="/browse-stock" className="cta-button">Browse stock</Link>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Get the latest Vanir Motors offers and van reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default Vans;