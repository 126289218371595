import React from 'react';
import './Complaints.css';

function Complaints() {
  return (
    <div className="complaints-container">
      <h1>Complaints Handling at Vanir</h1>
      
      <section>
        <p>
          At Vanir, we are committed to providing exceptional service to our customers. However, we understand that there may be times when our services do not meet your expectations. Our complaints handling process is designed to address your concerns promptly and fairly.
        </p>
      </section>
      
      <section>
        <h2>How to Make a Complaint</h2>
        <p>
          If you are dissatisfied with our service, we encourage you to let us know by:
        </p>
        <ul>
          <li>Calling us at: <strong>0151 673 1916</strong></li>
          <li>Emailing us at: <a href="mailto:sales@vanir.co.uk">sales@vanir.co.uk</a></li>
          <li>Writing to us at: Unit 9, Kirkdale House, Sefton Lane Industrial Estate, Liverpool L31 8BX</li>
        </ul>
        <p>
          You can submit your complaint verbally or in writing. When you contact us, please provide as much detail as possible about your issue, including any relevant dates, times, and the nature of your complaint.
        </p>
      </section>
      
      <section>
        <h2>What Happens After You Complain?</h2>
        <ul>
          <li><strong>Acknowledgment:</strong> We will acknowledge your complaint promptly, providing you with a reference number and details of the person handling your complaint.</li>
          <li><strong>Investigation:</strong> We will thoroughly investigate your complaint, which may involve contacting you for further information or clarification.</li>
          <li><strong>Progress Updates:</strong> We will keep you informed of the progress of our investigation. If we cannot resolve your complaint immediately, we will provide updates on our progress.</li>
          <li><strong>Resolution:</strong> We aim to resolve all complaints as quickly as possible. If your complaint cannot be resolved within three business days, we will issue a final response within eight weeks at the latest.</li>
        </ul>
      </section>
      
      <section>
        <h2>Resolution and Response</h2>
        <p>
          We are dedicated to resolving complaints to your satisfaction. Once we have completed our investigation, we will provide you with a final response that details our decision, the reasons behind it, and any actions we plan to take to remedy the issue.
        </p>
        <p>
          For complaints resolved within three business days, we will send a ‘Summary Resolution Communication’ confirming that the issue has been addressed.
        </p>
      </section>
      
      <section>
        <h2>Further Steps if Dissatisfied</h2>
        <p>
          If you are not satisfied with our resolution, you have the right to escalate your complaint to the Financial Ombudsman Service. You must do this within six months of receiving our final response. Here are the contact details for the Ombudsman:
        </p>
        <ul>
          <li>Phone: <strong>0800 023 4567</strong> or <strong>0300 123 9123</strong></li>
          <li>Email: <a href="mailto:complaint.info@financial-ombudsman.org.uk">complaint.info@financial-ombudsman.org.uk</a></li>
          <li>Website: <a href="https://www.financial-ombudsman.org.uk" target="_blank" rel="noopener noreferrer">www.financial-ombudsman.org.uk</a></li>
        </ul>
      </section>
      
      <section>
        <h2>Contact Details</h2>
        <p>
          If you have any questions or need to follow up on a complaint, please do not hesitate to contact our Complaints Manager using the details provided above.
        </p>
      </section>
    </div>
  );
}

export default Complaints;
