import React from 'react';
import './Terms.css';

function Terms() {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <section>
        <h2>Disclaimer</h2>
        <p>
          While Vanir.co.uk strives to ensure the accuracy and currency of the information on this website, no warranty or guarantee is provided regarding the quality or error-free nature of the website’s contents.
        </p>
        <p>
          Your use of the website’s content is solely at your risk. Vanir.co.uk explicitly disclaims any warranties or terms related to the products, services, information, or materials offered.
        </p>
        <p>
          The process of purchasing a new product may involve a waiting period from the time of order to delivery. Digital alterations may be applied to images for illustrative purposes.
        </p>
      </section>

      <section>
        <h2>Privacy Policy</h2>
        <p>
          Your privacy is important to us. For details on how we collect and use your data, please refer to our Privacy Policy.
        </p>
        <p>
          <a href="/privacy">View our Privacy Policy</a>
        </p>
      </section>

      <section>
        <h2>Cookie Policy</h2>
        <p>
          Our website uses cookies to enhance user experience. For more information on our cookie practices, please visit our Cookie Policy.
        </p>
        <p>
          <a href="/cookies">View our Cookie Policy</a>
        </p>
      </section>

      <section>
        <h2>Third Party Links</h2>
        <p>
          Vanir.co.uk may include links to external websites or resources for your convenience. We are not responsible for the content or availability of these external sites and do not endorse them. Your use of external links is at your discretion and risk.
        </p>
      </section>

      <section>
        <h2>Access</h2>
        <p>
          Access to this website is granted in accordance with these terms and conditions. Any purchases are subject to our general terms and conditions of sale.
        </p>
        <p>
          <a href="/terms-of-sale">View our Terms & Conditions of Sale</a>
        </p>
      </section>

      <section>
        <h2>Terms & Conditions for Product Finance</h2>
        <p>
          Vanir.co.uk acts as a broker, not a lender. Finance availability is subject to status and acceptance by the finance company. We may receive a commission from lenders, which does not vary based on the finance type or lender. A guarantee may be required, and finance rates can vary based on individual circumstances.
        </p>
      </section>

      <section>
        <h2>Telephone Calls</h2>
        <p>
          To enhance our service quality, telephone calls with our team may be monitored and recorded.
        </p>
      </section>

      <section>
        <h2>Website Use</h2>
        <p>
          All intellectual property on this website is owned by Vanir.co.uk or its affiliates, or is used under appropriate legal permission. The content is available for your personal, non-commercial use only. Any other use requires our written consent.
        </p>
      </section>

      <section>
        <h2>Competitions and Offers</h2>
        <p>
          Each competition and offer available on Vanir.co.uk will have its own set of rules and conditions, which will be made available with each specific promotion.
        </p>
      </section>

      <section>
        <h2>General</h2>
        <p>
          Vanir.co.uk reserves the right to modify, cancel, or withdraw any aspect of the website, terms, or competitions without notice. Participation in competitions signifies acceptance of these terms, which may be updated periodically.
        </p>
      </section>
    </div>
  );
}

export default Terms;
