import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Create a CSS file for styling

function Footer() {
  const [openSection, setOpenSection] = useState(null);
  // Toggle function to open or close a section
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const accordionData = [
    {
      title: 'Products & services',
      content: (
        <p>
          Discover our range of automotive products and services, including
          financing options, insurance, and extended warranties designed to
          meet your needs.
        </p>
      )
    },
    {
      title: 'Buying advice',
      content: (
        <p>
          Get expert advice on buying new and used vehicles, including tips on
          vehicle inspections, financing, and insurance options to make an
          informed choice.
        </p>
      )
    },
    {
      title: 'Quick search',
      content: (
        <p>
          Use our quick search feature to find vehicles by make, model, or
          budget. Discover a range of options that suit your preferences.
        </p>
      )
    },
    {
      title: 'Vanir Motors for dealers',
      content: (
        <p>
          Join our dealer network and expand your reach. We offer marketing,
          data analytics, and sales support tailored to help your dealership
          succeed.
        </p>
      )
    }
  ];

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4><Link to="/contact">Contact us</Link></h4>
          <h4><Link to="/terms">Terms & Conditions</Link></h4>
          <h4><Link to="/tcf">Treating Customers Fairly</Link></h4>
          <h4><Link to="/complaints">Complaints</Link></h4>
          <h4><Link to="/privacy">Privacy policy</Link></h4>
          <h4><Link to="/cookies">Cookies</Link></h4>
        </div>
        <div className="footer-section">
          {accordionData.map((section, index) => (
            <div key={section.title} className="accordion-section">
              <h4 
                className="accordion-title"
                onClick={() => toggleSection(index)}
              >
                <span className={`accordion-icon ${openSection === index ? 'open' : ''}`}>+</span> {section.title}
              </h4>
              {openSection === index && (
                <div className="accordion-content">
                  {section.content}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="footer-section">
          <p>Help us improve our website</p>
          <button className="feedback-button">Send feedback</button>
          <div className="social-icons">
            <span className="icon facebook"></span>
            <span className="icon youtube"></span>
            <span className="icon instagram"></span>
            <span className="icon tiktok"></span>
          </div>
          <div className="app-links">
            <img src="app-store-link.png" alt="Download on the App Store" />
            <img src="google-play-link.png" alt="Get it on Google Play" />
          </div>
        </div>
      </div>
      <div className="disclaimer">
        <p>
          &copy; 2024 Vanir is a trading style of John Charles Motors Ltd. John Charles Motors Ltd is a credit broker and not a lender, and are authorised and regulated by the Financial Conduct Authority, firm reference 928543. We can introduce you to a limited number of lenders, while providing details of finance products available. We will not charge you a fee for an introduction, but will typically receive a commission from the lender. Lender’s commissions may vary. The commission received does not influence the interest rate you will pay. For questions about commission, please speak to us. John Charles Motors Ltd, Tax Plus Bolton Road West, Ramsbottom, Bury, Lancashire, BL0 9ND.
        </p>
        <p>
          Representative finance examples are for illustrative purposes only. Finance is subject to status and credit acceptance. Terms and conditions apply. Available to 18s and over & UK residents only. Guarantees and/or indemnities may be required. Excess mileage and damage charges may apply when vehicle returned.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
