import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Trucks.css';

function Trucks() {
  const [showAllManufacturers, setShowAllManufacturers] = useState(false); // State to manage manufacturer visibility

  const toggleShowManufacturers = () => {
    setShowAllManufacturers(!showAllManufacturers); // Toggle visibility
  };
  return (
    <div className="trucks-page">
      {/* Hero Section */}
      <section className="trucks-hero">
        <h2>Find new & used trucks</h2>
        <form className="trucks-search-form">
          <input type="text" placeholder="Postcode" />
          <select>
            <option>Make</option>
            <option>Scania</option>
            <option>Volvo</option>
            {/* Add more options */}
          </select>
          <input type="text" placeholder="Keywords" />
          <select>
            <option>Category</option>
            <option>Tipper</option>
            <option>Flatbed</option>
            {/* Add more options */}
          </select>
          <button className="cta-button">Search 9,054 trucks</button>
          <div className="reset-filters">
            <Link to="/trucks">Reset filters</Link>
          </div>
        </form>
      </section>

      {/* Latest Adverts Section */}
      <section className="latest-adverts">
        <h2>Latest adverts</h2>
        <div className="adverts-grid">
          <div className="advert-card">DAF CF</div>
          <div className="advert-card">Montracon Tri Axle Trailer</div>
          <div className="advert-card">Mercedes-Benz Actros</div>
          <div className="advert-card">SDC Other</div>
        </div>
      </section>

      {/* Truck Services Section */}
      <section className="truck-services">
        <h2>Truck services and products</h2>
        <div className="services-grid">
          <div className="service-card">Commercial Truck Exports</div>
          <div className="service-card">Truck Parts & Accessories</div>
          <div className="service-card">Truck Rental, Hire & Lease</div>
        </div>
      </section>

      {/* Truck Manufacturers Section */}
      <section className="truck-manufacturers">
        <h2>Truck manufacturers and their models</h2>
        <div className="manufacturers-grid">
          <div className="manufacturer-card">
            <img src={require('../assets/logos/scania-logo.jpg')} alt="Scania Logo" />
            <span>Scania</span>
          </div>
          <div className="manufacturer-card">
            <img src={require('../assets/logos/volvo-logo.jpg')} alt="Volvo Logo" />
            <span>Volvo</span>
          </div>
          <div className="manufacturer-card">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="manufacturer-card">
            <img src={require('../assets/logos/daf-logo.jpg')} alt="DAF Logo" />
            <span>DAF</span>
          </div>
          {showAllManufacturers && (
            <>
              {/* Additional manufacturers */}
              <div className="manufacturer-card">
                <img src={require('../assets/logos/isuzu-logo.jpg')} alt="Isuzu Logo" />
                <span>Isuzu</span>
              </div>
              <div className="manufacturer-card">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="manufacturer-card">
                <img src={require('../assets/logos/man-logo.jpg')} alt="MAN Logo" />
                <span>MAN</span>
              </div>
              <div className="manufacturer-card">
                <img src={require('../assets/logos/mitsubishi-logo.jpg')} alt="Mitsubishi Logo" />
                <span>Mitsubishi</span>
              </div>
              <div className="manufacturer-card">
                <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
                <span>Renault</span>
              </div>
              {/* Add more manufacturers as needed */}
            </>
          )}
        </div>
        <button onClick={toggleShowManufacturers} className="view-all-manufacturers-btn">
          {showAllManufacturers ? 'Hide all manufacturers' : 'Show all manufacturers'}
        </button>
      </section>

      {/* Social Media Section */}
      <section className="social-media">
        <h2>Follow us on social media</h2>
        <p>All the latest news for you</p>
        <div className="social-links">
          <a href="#" className="social-icon">Facebook</a>
          <a href="#" className="social-icon">YouTube</a>
          <a href="#" className="social-icon">Instagram</a>
          <a href="#" className="social-icon">TikTok</a>
        </div>
      </section>
    </div>
  );
}

export default Trucks;
