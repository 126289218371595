import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CarFinance.css';

function CarFinance() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "Where is the best place to buy a car?", answer: "You can buy a car from dealerships, online marketplaces, or directly from individuals.", isOpen: false },
    { question: "How do I check my credit score?", answer: "You can check your credit score through various online services or financial institutions.", isOpen: false },
    { question: "What is car finance?", answer: "Car finance is a way to spread the cost of a car over a period of time.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="car-finance-page">
      {/* Hero Section */}
      <section className="finance-hero">
        <h2>Car finance</h2>
        <p>See cars available on finance near you</p>
        <form className="finance-search-form">
          <select>
            <option>Personal</option>
            <option>Business</option>
          </select>
          <input type="text" placeholder="Postcode" />
          <select>
            <option>Make</option>
            <option>Audi</option>
            <option>BMW</option>
          </select>
          <select>
            <option>Model</option>
            <option>A4</option>
            <option>3 Series</option>
          </select>
          <button className="cta-button">Search deals</button>
        </form>
        <p className="learn-more">
          <Link to="/finance-info">How much can I afford?</Link>
        </p>
      </section>

      {/* Popular Brands Section */}
      <section className="finance-brands">
        <h2>View finance deals from popular brands</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/audi-logo.jpg')} alt="Audi Logo" />
            <span>Audi</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/bmw-logo.jpg')} alt="BMW Logo" />
                <span>BMW</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/honda-logo.jpg')} alt="Honda Logo" />
                <span>Honda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/kia-logo.jpg')} alt="Kia Logo" />
                <span>Kia</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Finance Info Section */}
      <section className="finance-info">
        <h2>How finance works on Vanir Motors</h2>
        <p>
          Simply input your location to see vehicles available on finance or explore our various finance options.
        </p>
        <p>
          We offer a broad selection of vehicles with various finance options tailored to suit your needs.
        </p>
        <Link to="/finance-options" className="cta-button">Learn more about financing</Link>
      </section>

      {/* Monthly Prices Section */}
      <section className="monthly-prices">
        <div className="info-box">
          <h3>See monthly prices upfront</h3>
          <p>
            You can find vehicles on PCP, HP, or CS finance based on your preferences.
          </p>
          <Link to="/finance-prices" className="cta-button">Learn more</Link>
        </div>
      </section>

      {/* Types of Finance Section */}
      <section className="types-of-finance">
        <h2>Types of finance available</h2>
        <div className="finance-type-grid">
          <div className="finance-type-card">Personal Contract Purchase (PCP)</div>
          <div className="finance-type-card">Hire Purchase (HP)</div>
          <div className="finance-type-card">Personal Contract Hire (PCH)</div>
          <div className="finance-type-card">Conditional Sale (CS)</div>
        </div>
      </section>

      {/* Video Section */}
      <section className="finance-video">
        <h2>New to car finance?</h2>
        <iframe
          title="Car Finance Video"
          src="https://www.youtube.com/embed/sample-video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </section>

      {/* Finance Guides Section */}
      <section className="finance-guides">
        <h2>Car finance guides</h2>
        <div className="guides-grid">
          <div className="guide-card">Tips on applying for car finance</div>
          <div className="guide-card">What to do if you have a poor credit score</div>
          <div className="guide-card">Which type of finance is right for you</div>
          <div className="guide-card">Buying a car on finance</div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default CarFinance;
