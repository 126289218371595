import React from 'react';
import './Privacy.css';

function Privacy() {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>

      <section>
        <h2>Introduction</h2>
        <p>
          At Vanir.co.uk, we are committed to safeguarding the privacy of our website visitors and service users. This policy sets out how we will treat your personal information.
        </p>
      </section>

      <section>
        <h2>What Information Do We Collect?</h2>
        <p>We may collect, store, and use the following kinds of personal information:</p>
        <ul>
          <li>Information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views, and website navigation paths).</li>
          <li>Information that you provide to us when registering with our website (including your email address).</li>
          <li>Information that you provide when completing your profile on our website (including your name, profile pictures, gender, date of birth, relationship status, interests and hobbies, educational details, and employment details).</li>
          <li>Information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters (including your name and email address).</li>
          <li>Information relating to any purchases you make of our goods or services or any other transactions that you enter into through our website (including your name, address, telephone number, email address, and card details).</li>
          <li>Information that you post to our website for publication on the internet (including your user name, your profile pictures, and the content of your posts).</li>
          <li>Any other personal information that you choose to send to us.</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>Personal information submitted to us through our website will be used for the purposes specified in this policy or on the relevant pages of the website. We may use your information to:</p>
        <ul>
          <li>Administer our website and business.</li>
          <li>Personalize our website for you.</li>
          <li>Enable your use of the services available on our website.</li>
          <li>Send you goods purchased through our website.</li>
          <li>Supply to you services purchased through our website.</li>
          <li>Send statements, invoices, and payment reminders to you, and collect payments from you.</li>
          <li>Send you non-marketing commercial communications.</li>
          <li>Send you email notifications that you have specifically requested.</li>
          <li>Send you our email newsletter if you have requested it (you can inform us at any time if you no longer require the newsletter).</li>
          <li>Deal with inquiries and complaints made by or about you relating to our website.</li>
          <li>Keep our website secure and prevent fraud.</li>
          <li>Verify compliance with the terms and conditions governing the use of our website.</li>
        </ul>
      </section>

      <section>
        <h2>Disclosing Personal Information</h2>
        <p>We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.</p>
        <p>We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) as reasonably necessary for the purposes set out in this policy.</p>
        <p>We may also disclose your personal information:</p>
        <ul>
          <li>To the extent that we are required to do so by law.</li>
          <li>In connection with any ongoing or prospective legal proceedings.</li>
          <li>In order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</li>
          <li>To the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling.</li>
          <li>To any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</li>
        </ul>
      </section>

      <section>
        <h2>International Data Transfers</h2>
        <p>Information that we collect may be stored and processed in and transferred between any of the countries in which we operate to enable us to use the information in accordance with this policy.</p>
        <p>Information that we collect may be transferred to countries which do not have data protection laws equivalent to those in force in the European Economic Area.</p>
      </section>

      <section>
        <h2>Security of Personal Information</h2>
        <p>We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information.</p>
        <p>We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</p>
        <p>All electronic financial transactions entered into through our website will be protected by encryption technology.</p>
      </section>

      <section>
        <h2>Amendments</h2>
        <p>We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy.</p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to the following:</p>
        <ul>
          <li>The payment of a fee (currently fixed at GBP 10); and</li>
          <li>The supply of appropriate evidence of your identity.</li>
        </ul>
        <p>We may withhold personal information that you request to the extent permitted by law.</p>
        <p>You may instruct us at any time not to process your personal information for marketing purposes.</p>
      </section>

      <section>
        <h2>Third-Party Websites</h2>
        <p>Our website includes hyperlinks to, and details of, third-party websites. We have no control over, and are not responsible for, the privacy policies and practices of third parties.</p>
      </section>

      <section>
        <h2>Updating Information</h2>
        <p>Please let us know if the personal information that we hold about you needs to be corrected or updated.</p>
      </section>

      <section>
        <h2>Cookies</h2>
        <p>Our website uses cookies. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either “persistent” cookies or “session” cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions about this privacy policy or our treatment of your personal information, please write to us by email to <a href="mailto:sales@vanir.co.uk">sales@vanir.co.uk</a> or by post to Unit 12, Kirkdale House, Sefton Lane Industrial Estate, Liverpool L31 8BX.</p>
      </section>

      <section>
        <h2>Data Controller</h2>
        <p>The data controller responsible for our website is John Charles Motors LTD. Our data protection registration number is ZB439421.</p>
      </section>
    </div>
  );
}

export default Privacy;
