import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UsedTrucks.css';

function UsedTrucks() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  return (
    <div className="used-trucks-page">
      {/* Hero Section */}
      <section className="used-trucks-hero">
        <h2>Used Trucks</h2>
        <p>Browse pre-owned trucks</p>
        <form className="used-trucks-search-form">
          <input type="text" placeholder="Postcode" />
          <select>
            <option>Distance</option>
            <option>Local</option>
            <option>National</option>
          </select>
          <select>
            <option>Mile</option>
            <option>0-50 miles</option>
            <option>50-100 miles</option>
          </select>
          <button className="cta-button">Search all trucks</button>
        </form>
      </section>

      {/* Truck Categories */}
      <section className="truck-categories">
        <h2>Find a truck to suit you</h2>
        <div className="categories-grid">
          <div className="category-card">Rigid Trucks</div>
          <div className="category-card">Tractor Units</div>
          <div className="category-card">Trailers</div>
        </div>
      </section>

      {/* Truck History Section */}
      <section className="truck-history">
        <div className="info-box">
          <h3>Truck history checks</h3>
          <p>Every truck comes with a background history check to ensure transparency.</p>
        </div>
        <div className="image-box">
          <img src="/path/to/truck-image.jpg" alt="Truck" />
        </div>
      </section>

      {/* Expert Picks Section */}
      <section className="expert-picks">
        <h2>Top picks from the experts</h2>
        <div className="picks-grid">
          <div className="pick-card">5 Best Electric Trucks</div>
          <div className="pick-card">5 Best Recovery Trucks</div>
          <div className="pick-card">5 Best MAN Units</div>
        </div>
        <Link to="/articles" className="view-more-articles">See more articles</Link>
      </section>

      {/* Brands Section */}
      <section className="truck-brands">
        <h2>Browse by brand</h2>
        <div className="brands-grid">
          <div className="brand-card">
            <img src={require('../assets/logos/scania-logo.jpg')} alt="Scania Logo" />
            <span>Scania</span>
          </div>
          <div className="brand-card">
            <img src={require('../assets/logos/daf-logo.jpg')} alt="DAF Logo" />
            <span>DAF</span>
          </div>
          <div className="brand-card">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-card">
            <img src={require('../assets/logos/volvo-logo.jpg')} alt="Volvo Logo" />
            <span>Volvo</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-card">
                <img src={require('../assets/logos/isuzu-logo.jpg')} alt="Isuzu Logo" />
                <span>Isuzu</span>
              </div>
              <div className="brand-card">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="brand-card">
                <img src={require('../assets/logos/man-logo.jpg')} alt="MAN Logo" />
                <span>MAN</span>
              </div>
              <div className="brand-card">
                <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
                <span>Renault</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Reviews Section */}
      <section className="reviews">
        <h2>Need a second opinion?</h2>
        <div className="reviews-grid">
          <div className="review-card">Scania R Series Review</div>
          <div className="review-card">DAF LF Review</div>
          <div className="review-card">Mercedes-Benz Actros Review</div>
          <div className="review-card">DAF CF Review</div>
        </div>
        <Link to="/reviews" className="view-more-reviews">See more reviews</Link>
      </section>

      {/* Stock Summary Section */}
      <section className="stock-summary">
        <h2>8,438 pre-owned trucks ready and waiting</h2>
        <button className="cta-button">Find yours</button>
      </section>

      {/* About Us Section */}
      <section className="about-us-summary">
        <h2>Used truck experts since before the internet</h2>
        <p>With years of experience, Vanir Motors is here to help you find the right used truck for your needs.</p>
        <Link to="/about" className="about-us-link">About Vanir Motors</Link>
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default UsedTrucks;
