import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './VanLeasing.css';

function VanLeasing() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to toggle brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "Who can lease a van?", answer: "Van leasing is available to individuals and businesses with a steady income and an acceptable credit profile. Generally, you must be at least 18 years old and meet the leasing company’s income and credit requirements. Business owners, freelancers, and companies often lease vans to reduce upfront costs and enjoy lower monthly payments, while individuals may lease for personal or business use if they prefer flexibility without committing to ownership.", isOpen: false },
    { question: "How does van leasing work?", answer: "Van leasing is similar to a long-term rental. You agree to an initial deposit and pay monthly installments for a set term, usually 2-5 years. The agreement includes terms on mileage limits and vehicle condition requirements. During the lease, you can use the van as if it were your own, but you do not own it. At the end of the lease, you typically return the van to the leasing company or, in some cases, have the option to purchase it at a predetermined price.", isOpen: false },
    { question: "What happens at the end of the lease agreement?", answer: "At the end of a lease, you usually return the van to the leasing company. They will inspect the vehicle for mileage overages and excessive wear and tear, and you may be charged for any excess. Some leases also offer a purchase option, allowing you to buy the van for its residual value if you wish to keep it. Alternatively, you may choose to begin a new lease on a different van or model if you want to upgrade.", isOpen: false },
    { question: "What kind of credit score do you need?", answer: "Leasing typically requires a good to excellent credit score, generally around 650 or higher. A higher credit score improves your chances of being approved with favorable terms, such as lower interest rates and lower monthly payments. Those with lower scores may still be eligible but could face higher rates, larger down payments, or additional conditions. Each leasing company has its criteria, so it’s worth checking with multiple providers if you’re unsure.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="van-leasing-page">
      {/* Hero Section */}
      <section className="van-leasing-hero">
        <h2>Van Leasing</h2>
        <p>Lease a brand new van</p>
        <form className="van-leasing-search-form">
          <select>
            <option>Personal</option>
            <option>Business</option>
          </select>
          <select>
            <option>Make</option>
            <option>Ford</option>
            <option>Volkswagen</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Monthly Budget</option>
            <option>Up to £300</option>
            <option>£300-£500</option>
            {/* Add more options */}
          </select>
          <button className="cta-button">Find your lease</button>
        </form>
        <p className="learn-more">
          <Link to="/leasing-info">How much can I afford?</Link>
        </p>
      </section>


      {/* Best Deals Section */}
      <section className="best-deals">
        <h2>Our best deals</h2>
        <div className="deals-grid">
          <div className="deal-card">Deal 1</div>
          <div className="deal-card">Deal 2</div>
          <div className="deal-card">Deal 3</div>
          <div className="deal-card">Deal 4</div>
        </div>
      </section>

      {/* Popular Brands Section */}
      <section className="popular-brands">
        <h2>Leasing deals on popular van brands</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
            <span>Renault</span>
          </div>
          {showAllBrands && (
            <>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/vauxhall-logo.jpg')} alt="Vauxhall Logo" />
                <span>Vauxhall</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/citroen-logo.jpg')} alt="Citroen Logo" />
                <span>Citroen</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Leasing Information Section */}
      <section className="leasing-info">
        <h2>What is van leasing?</h2>
        <div className="info-box">
          <p>Learn about the benefits of van leasing, including lower monthly payments, flexible terms, and more.</p>
          <iframe 
            title="Van Leasing Explained" 
            src="https://www.youtube.com/embed/your-video-id" 
            className="video-frame">
          </iframe>
        </div>
      </section>

      {/* Leasing Guides Section */}
      <section className="leasing-guides">
        <h2>More about leasing</h2>
        <div className="guides-grid">
          <div className="guide-card">Rest Small Vans to Lease</div>
          <div className="guide-card">Van Leasing Wear and Tear</div>
          <div className="guide-card">Do I Need to Insure a Leased Van?</div>
          <div className="guide-card">What Happens at the End of a Van Lease?</div>
        </div>
        <Link to="/leasing-articles" className="view-more-articles">Read more articles</Link>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Summary Section */}
      <section className="about-us-summary">
        <h2>About Vanir Motors</h2>
        <p>With over 40 years of experience, Vanir Motors is dedicated to bringing you the best deals.</p>
        <p>Trustpilot Rating: 4.7/5 based on 9,250 reviews.</p>
        <Link to="/about" className="about-us-link">About Vanir Motors</Link>
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default VanLeasing;
