import React, { useEffect, useState, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './VehicleDetail.css';

// Lazy load ImageGallery component
const ImageGallery = React.lazy(() => import('react-image-gallery'));

const VehicleDetail = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGallery, setShowGallery] = useState(false);
  const [view, setView] = useState("images");

  useEffect(() => {
    const fetchVehicle = async () => {
      try {
        const response = await axios.get(`https://vanirsales.com/api/vehicles/${id}`);
        setVehicle(response.data);
      } catch (error) {
        console.error('Error fetching vehicle details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicle();
  }, [id]);

  if (loading) {
    return <p>Loading vehicle details...</p>;
  }

  if (!vehicle) {
    return <p>Vehicle not found.</p>;
  }

  const make = vehicle?.vehicle?.make || 'Unknown';
  const model = vehicle?.vehicle?.model || 'Unknown';
  const derivative = vehicle?.vehicle?.derivative || 'Unknown';
  const yearOfManufacture = vehicle?.vehicle?.yearOfManufacture || 'N/A';
  const odometerReadingMiles = vehicle?.vehicle?.odometerReadingMiles || 'N/A';
  const transmissionType = vehicle?.vehicle?.transmissionType || 'N/A';
  const fuelType = vehicle?.vehicle?.fuelType || 'N/A';
  const bodyType = vehicle?.vehicle?.bodyType || 'N/A';
  const engineSize = vehicle?.vehicle?.engineSize || 'N/A';
  const doors = vehicle?.vehicle?.doors || 'N/A';
  const seats = vehicle?.vehicle?.seats || 'N/A';
  const serviceHistory = vehicle?.vehicle?.serviceHistory || 'N/A';
  const emissionClass = vehicle?.vehicle?.emissionClass || 'N/A';
  const owners = vehicle?.vehicle?.owners || 'N/A';
  const images = vehicle?.media?.images || ['placeholder.jpg'];

  return (
    <div className="vehicle-detail-container">
      <div className="vehicle-detail">
        <div className="vehicle-detail__images">
          <img className="vehicle-detail__main-image" src={images[0] || 'placeholder.jpg'} alt={`${make} ${model}`} />
          <div className="vehicle-detail__thumbnail-row">
            {images.slice(1, 4).map((img, index) => (
              <img key={index} className="vehicle-detail__thumbnail" src={img} alt={`${make} ${model} thumbnail`} />
            ))}
          </div>
        </div>

        <div className="vehicle-detail__info">
          <div className="vehicle-detail__title">
            <span className="vehicle-detail__make-model">{make} {model}</span>
            <span className="vehicle-detail__derivative">{derivative}</span>
          </div>
          <div className="vehicle-detail__price">£{vehicle.adverts?.retailAdverts?.totalPrice?.amountGBP || 'N/A'}</div>
          
          {/* Styled List Items */}
          <ul className="vehicle-detail__specs">
            <li className="vehicle-detail__spec-item">{yearOfManufacture}</li>
            <li className="vehicle-detail__spec-item">{odometerReadingMiles} miles</li>
            <li className="vehicle-detail__spec-item">{transmissionType}</li>
            <li className="vehicle-detail__spec-item">{fuelType}</li>
          </ul>
        </div>
      </div>

      {/* Overview Section */}
      <div className="vehicle-detail__overview">
        <h2>Overview</h2>
        <div className="vehicle-detail__overview-top">
          <div className="vehicle-detail__overview-item">
            <div className="vehicle-detail__overview-icon">🚗</div>
            <div className="vehicle-detail__overview-content">
              <h3>Mileage</h3>
              <p>{odometerReadingMiles} miles</p>
              <p className="vehicle-detail__mileage-note">2,847 miles above average</p>
            </div>
          </div>
          <div className="vehicle-detail__overview-item">
            <div className="vehicle-detail__overview-icon">📅</div>
            <div className="vehicle-detail__overview-content">
              <h3>Registration</h3>
              <p>{yearOfManufacture}</p>
            </div>
          </div>
          <div className="vehicle-detail__overview-item">
            <div className="vehicle-detail__overview-icon">👤</div>
            <div className="vehicle-detail__overview-content">
              <h3>Owners</h3>
              <p>{owners}</p>
            </div>
          </div>
        </div>

        <div className="vehicle-detail__specs-list">
          <div className="vehicle-detail__spec-row"><span>Fuel type</span><span>{fuelType}</span></div>
          <div className="vehicle-detail__spec-row"><span>Body type</span><span>{bodyType}</span></div>
          <div className="vehicle-detail__spec-row"><span>Engine</span><span>{engineSize}</span></div>
          <div className="vehicle-detail__spec-row"><span>Gearbox</span><span>{transmissionType}</span></div>
          <div className="vehicle-detail__spec-row"><span>Doors</span><span>{doors}</span></div>
          <div className="vehicle-detail__spec-row"><span>Seats</span><span>{seats}</span></div>
          <div className="vehicle-detail__spec-row"><span>Service history</span><span>{serviceHistory}</span></div>
          <div className="vehicle-detail__spec-row"><span>Emission class</span><span>{emissionClass}</span></div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetail;
