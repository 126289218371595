import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UsedVans.css';

function UsedVans() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "What is the best time to buy a used van?", answer: "The best times to buy a used van are often at the end of the year or during the transition between new model years when dealerships may offer deals on older stock. Additionally, buying at the end of a financial quarter (March, June, September, December) can sometimes yield better deals, as sellers are eager to meet sales targets. Consider shopping during holiday sales or off-peak months, such as January and February, when demand is lower, which may also lead to better pricing.", isOpen: false },
    { question: "Can I lease a used van?", answer: "Yes, leasing a used van is an option, though it's less common than leasing new vehicles. Some dealerships and leasing companies offer certified pre-owned (CPO) vans for lease, often with slightly lower monthly payments compared to new van leases. Used van leases can be ideal if you’re looking for lower payments without the long-term commitment of ownership. Be sure to verify the van’s condition and the specific terms, as mileage and wear-and-tear guidelines still apply.", isOpen: false },
    { question: "What should I look for in a used van?", answer: "When evaluating a used van, check the exterior and interior for signs of wear, rust, and damage. Inspect the engine, brakes, tires, and suspension to ensure they’re in good working order. Review the van’s service history, mileage, and prior ownership to understand its maintenance record. Additionally, check that all electronics and features, such as air conditioning, lights, and locks, are functioning properly. If possible, have a mechanic perform an inspection before purchase.", isOpen: false },
    { question: "How do I get a van history report?", answer: "You can obtain a van history report through services like HPI Check, Carfax, or AutoCheck by entering the van’s Vehicle Identification Number (VIN). A history report reveals important details, including any previous accidents, recorded mileage, title issues, service history, and whether the vehicle was reported stolen or used commercially. Some dealers may provide a report for free, so always ask before purchasing.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="used-vans-page">
      {/* Hero Section */}
      <section className="vans-hero">
        <h2>Used Vans</h2>
        <p>The UK's largest choice of pre-loved vans</p>
        <form className="vans-search-form">
          <select>
            <option>Make</option>
            <option>Ford</option>
            <option>Volkswagen</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Model</option> 
            <option>Transit</option>
            <option>Transporter</option>
            {/* Add more options */}
          </select>
          <button className="cta-button">Search vans</button>
        </form>
      </section>

      {/* Find a Van Section */}
      <section className="find-a-van">
        <h2>Find a van that suits you</h2>
        <div className="van-categories">
          <div className="van-category">Panel Vans</div>
          <div className="van-category">Double Cabs</div>
          <div className="van-category">Chassis Vans</div>
          <div className="van-category">Electric Vans</div>
        </div>
      </section>


      {/* Browse by Brand Section */}
      <section className="browse-brands">
        <h2>Browse vans by brand</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
            <span>Renault</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/citroen-logo.jpg')} alt="Citroen Logo" />
                <span>Citroen</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/fiat-logo.jpg')} alt="Fiat Logo" />
                <span>Fiat</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/man-logo.jpg')} alt="Man Logo" />
                <span>Man</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/maxus-logo.jpg')} alt="Maxus Logo" />
                <span>Maxus</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="toyota Logo" />
                <span>toyota</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/vauxhall-logo.jpg')} alt="Vauxhall Logo" />
                <span>Vauxhall</span>
              </div>
              
              {/* Add more brands as needed */}
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Expert Picks Section */}
      <section className="expert-picks">
        <h2>Top picks from the experts</h2>
        <div className="picks-grid">
          <div className="pick-card">Best small vans</div>
          <div className="pick-card">Best electric vans</div>
          <div className="pick-card">Best value for money</div>
          <div className="pick-card">Best high-capacity vans</div>
        </div>
        <Link to="/expert-reviews" className="view-more-reviews">See more reviews</Link>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Get the latest Vanir Motors offers and van reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default UsedVans;
