import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './VanFinance.css';

function VanFinance() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "How much can I borrow to buy a van?", answer: "The amount you can borrow depends on factors like your credit score, income, and existing debts. Lenders will assess your financial situation to determine an affordable loan amount, typically ranging from a few thousand to tens of thousands of pounds. They also consider the van’s value, deposit amount, and loan term. Many lenders provide online calculators to give an estimate, but it's always best to discuss options with your bank or dealership to get an accurate quote based on your financial profile.", isOpen: false },
    { question: "What is APR?", answer: "APR stands for Annual Percentage Rate, which represents the total cost of borrowing on a loan or finance agreement, expressed as an annual percentage. It includes both the interest rate and any fees associated with the loan, giving you a clear picture of the total yearly cost. APR helps compare financing options, as a lower APR generally means lower overall costs. However, keep in mind that individual payments and terms may vary, so it’s important to look at the complete breakdown of charges.", isOpen: false },
    { question: "What is mileage allowance/excess mileage?", answer: "In leasing agreements, mileage allowance is the maximum number of miles you’re allowed to drive annually without incurring extra charges, usually set based on your expected usage. If you exceed this limit, excess mileage charges apply, calculated per mile over the allowance. These fees can add up, so it’s important to choose a mileage plan that reflects your driving needs. Mileage limits typically vary depending on the lease terms, with higher allowances available for those who expect to drive more.", isOpen: false },
    { question: "Where does the finance information come from?", answer: "Finance information is sourced from various places, including banks, financial institutions, and dealership financing departments. Many dealers partner with lenders to offer financing options tailored to vehicle purchases. Online comparison websites also gather data from multiple lenders to present rates and terms side-by-side. For accurate and personalized advice, it’s best to consult directly with your bank, a reputable lender, or a financial advisor, as they provide guidance specific to your creditworthiness and financial situation.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="van-finance-page">
      {/* Hero Section */}
      <section className="van-finance-hero">
        <h2>Van Finance</h2>
        <p>See vans available on finance near you</p>
        <form className="van-finance-search-form">
          <select>
            <option>Personal</option>
            <option>Business</option>
          </select>
          <input type="text" placeholder="Postcode" />
          <button className="cta-button">Search 24,171 deals</button>
        </form>
        <p className="learn-more">
          <Link to="/finance-info">How much can I afford?</Link>
        </p>
      </section>

      {/* Information Section */}
      <section className="finance-info">
        <div className="info-box">
          <h3>How finance works on Vanir Motors</h3>
          <p>We work as an independent partner with finance providers to offer you the best deals.</p>
        </div>
        <div className="info-box">
          <h3>24,171 vans available today on finance</h3>
          <img src="/path/to/van-image.jpg" alt="Van" />
        </div>
      </section>

      {/* Popular Brands Section */}
      <section className="browse-brands">
        <h2>Popular Van Brands on Finance</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
            <span>Renault</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/citroen-logo.jpg')} alt="Citroen Logo" />
                <span>Citroen</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/fiat-logo.jpg')} alt="Fiat Logo" />
                <span>Fiat</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/man-logo.jpg')} alt="Man Logo" />
                <span>Man</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/maxus-logo.jpg')} alt="Maxus Logo" />
                <span>Maxus</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="toyota Logo" />
                <span>toyota</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/vauxhall-logo.jpg')} alt="Vauxhall Logo" />
                <span>Vauxhall</span>
              </div>
              
              {/* Add more brands as needed */}
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Pricing Section */}
      <section className="pricing-info">
        <div className="price-box">
          <h3>See monthly prices upfront</h3>
          <p>Compare rates and find the right finance option for your budget.</p>
        </div>
      </section>

      {/* Types of Finance Section */}
      <section className="finance-types">
        <h2>Types of finance available</h2>
        <div className="finance-grid">
          <div className="finance-type">
            <h3>Personal Contract Purchase (PCP)</h3>
            <p>Flexible options for personal use with the option to own the van at the end.</p>
          </div>
          <div className="finance-type">
            <h3>Hire Purchase (HP)</h3>
            <p>Spread the cost of your van over monthly payments and own it outright.</p>
          </div>
          <div className="finance-type">
            <h3>Personal Contract Hire (PCH)</h3>
            <p>Lower monthly payments with the flexibility to return the van.</p>
          </div>
        </div>
      </section>

      {/* Guides Section */}
      <section className="finance-guides">
        <h2>Still confused? Check out our van finance guides</h2>
        <div className="guides-grid">
          <div className="guide-card">Van Finance Explained</div>
          <div className="guide-card">Applying for Van Finance</div>
          <div className="guide-card">Your Van Finance Options</div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Summary Section */}
      <section className="finance-summary">
        <h2>Helping you compare finance options in one place</h2>
        <p>Compare different finance options with Vanir Motors to find the best deal for your needs.</p>
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default VanFinance;
