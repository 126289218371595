import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './AboutPage.css';

function AboutPage() {
  return (
    <div className="about-page">
      <section className="about-hero">
        <h2>About Us - Vanir Motors</h2>
        <p>
          Welcome to Vanir Motors, your trusted dealership for cars, vans, and trucks in the heart of Liverpool. With a passion for vehicles and a commitment to exceptional customer service, we offer a wide range of options to suit every driver’s needs.
        </p>
      </section>

      <section className="about-content">
        <h3>Your Vehicle, Your Way</h3>
        <p>
          Whether you're looking to buy a vehicle outright, finance your purchase, or explore leasing and contract hire options, Vanir Motors has you covered. Our extensive inventory includes everything from used and new cars to vans and trucks, ensuring that you’ll find the perfect vehicle for your needs.
        </p>
        
        <h3>Why Choose Vanir Motors?</h3>
        <p>
          At Vanir Motors, we understand that purchasing a vehicle is a significant decision. That’s why our team is dedicated to providing transparent advice, competitive pricing, and a hassle-free experience. With us, you can drive away with confidence, knowing you've made the right choice.
        </p>

        <h3>Visit Us in Liverpool</h3>
        <p>
          Visit us in Liverpool today and discover why so many customers trust Vanir Motors for their vehicle needs. Your journey starts here.
        </p>
        
        <Link to="/contact" className="cta-button">Contact Us</Link>
      </section>
    </div>
  );
}

export default AboutPage;
