import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CarLeasing.css';

function CarLeasing() {
  const [showAllBrands, setShowAllBrands] = useState(false);  // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands);  // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "How much is car leasing?", answer: "Car leasing costs vary based on factors like the car's make and model, the lease term, mileage limits, and your credit score. Typically, monthly payments are lower than a car loan payment for the same vehicle. You may also need to pay an initial deposit, often equal to a few months’ payments. Additional charges can apply if you exceed the mileage limit or if there’s excessive wear and tear at the end of the lease. On average, car leasing can range from a few hundred to several hundred pounds per month, depending on the vehicle and contract specifics.", isOpen: false },
    { question: "How does car leasing work?", answer: "Car leasing is essentially a long-term rental. You pay an initial deposit and then make monthly payments for a set period (usually 2-4 years). The lease agreement typically includes mileage limits and guidelines on the vehicle's condition. During the lease, you can drive the car as if it were your own, but at the end, you return it to the leasing company unless you have a lease-to-own option. Leasing can offer access to a newer car with lower payments than buying but comes with usage restrictions.", isOpen: false },
    { question: "What happens at the end of leasing a car?", answer: "At the end of a car lease, you have a few options. Most commonly, you return the car to the leasing company and settle any excess mileage or damage fees. Some leases offer a purchase option where you can buy the car for its residual value, which is the estimated value at the end of the lease. Alternatively, you might choose to trade in the leased car for a new lease on another vehicle. The specific terms for end-of-lease actions are outlined in your lease agreement.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="car-leasing-page">
      {/* Hero Section */}
      <section className="leasing-hero">
        <h2>Lease a brand new car</h2>
        <form className="leasing-form">
          <select>
            <option>Personal</option>
            <option>Business</option>
          </select>
          <select>
            <option>Make</option>
            <option>Audi</option>
            <option>BMW</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Model</option>
            <option>A4</option>
            <option>3 Series</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Min Price</option>
            <option>£100</option>
            <option>£200</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Max Price</option>
            <option>£500</option>
            <option>£1000</option>
            {/* Add more options */}
          </select>
          <button className="cta-button">Find lease deals</button>
        </form>
      </section>

      {/* Promotion Banner */}
      <section className="promotion-banner">
        <h2>Great car leasing deals found</h2>
        <p>In stock and available now.</p>
      </section>

      {/* Best Deals Section */}
      <section className="best-deals">
        <h2>Our best deals</h2>
        <div className="deals-carousel">
          <div className="deal-card">Deal 1</div>
          <div className="deal-card">Deal 2</div>
          <div className="deal-card">Deal 3</div>
          <div className="deal-card">Deal 4</div>
        </div>
        <Link to="/deals" className="view-all-deals">View more deals</Link>
      </section>

      {/* Video Section */}
      <section className="leasing-video">
        <h2>What is car leasing?</h2>
        <iframe
          title="Car Leasing Video"
          src="https://www.youtube.com/embed/sample-video"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </section>

      {/* More About Leasing Section */}
      <section className="more-about-leasing">
        <h2>More about leasing</h2>
        <div className="articles-grid">
          <div className="article-card">Complete guide to leasing a car</div>
          <div className="article-card">Business vs. personal vehicle leasing</div>
          <div className="article-card">Excess mileage charges explained</div>
          <div className="article-card">What does initial payment mean?</div>
        </div>
        <Link to="/articles" className="view-more-articles">Read more articles</Link>
      </section>

      {/* Popular Brands Section */}
      <section className="popular-brands">
        <h2>Leasing deals on popular brands</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/audi-logo.jpg')} alt="Audi Logo" />
            <span>Audi</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/bmw-logo.jpg')} alt="BMW Logo" />
            <span>BMW</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/kia-logo.jpg')} alt="Kia Logo" />
                <span>Kia</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* About Us Section */}
      <section className="about-us">
        <h2>About Vanir Motors</h2>
        <p>We are experts in vehicle leasing and finance, offering tailored leasing solutions to suit every driver’s needs.</p>
        <Link to="/about" className="cta-button">Learn more about us</Link>
      </section>
    </div>
  );
}

export default CarLeasing;
