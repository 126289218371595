import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NewCars.css';

function NewCars() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "What is the difference between PCP and HP?", answer: "Personal Contract Purchase (PCP) and Hire Purchase (HP) are both financing options but work differently. With PCP, you pay lower monthly installments and have the option to make a final balloon payment at the end to own the car. Alternatively, you can return the car or trade it in. With HP, you pay higher monthly payments but own the car outright after completing the payment term. PCP offers flexibility at the end of the contract, while HP usually means higher costs monthly but guaranteed ownership at the end.", isOpen: false },
    { question: "How do I get the best deal on a new car?", answer: "To get the best deal on a new car, start by comparing prices across multiple dealerships and online platforms. Negotiate with dealers and look for any end-of-year or model-year clearance sales where discounts are common. Also, check for manufacturer incentives, rebates, or special financing rates. Timing can be key; dealerships often offer deals at the end of the month or quarter to meet sales targets. Finally, if you have a trade-in, make sure to negotiate its value as part of the overall deal.", isOpen: false },
    { question: "Is there a benefit to buying new?", answer: "Buying a new car has several benefits, including access to the latest technology, safety features, and fuel efficiency. New cars come with a full warranty, meaning fewer repair costs initially. You also have the chance to customize the vehicle exactly to your preferences. Additionally, a new car is less likely to need significant maintenance and may have lower emissions, which could reduce road tax in some regions.", isOpen: false },
    { question: "What new car incentives are available?", answer: "Many car manufacturers and dealerships offer incentives on new cars, such as cashback offers, low or 0% APR financing, and lease specials. Some may provide loyalty discounts if you’ve purchased from them before, or they may offer bonus incentives for specific models to clear inventory. Eco-friendly cars may qualify for government grants or tax credits. Check with dealerships and manufacturers to see what incentives apply, as these offers can vary based on time of year and location.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="new-cars-page">
      {/* Hero Section */}
      <section className="new-cars-hero">
        <h2>Brand New Cars</h2>
        <p>Find the latest new car deals near you</p>
        <form className="new-cars-search-form">
          <select>
            <option>Personal</option>
            <option>Business</option>
          </select>
          <input type="text" placeholder="Postcode" />
          <button className="cta-button">Search 74,000+ new cars</button>
        </form>
        <p className="learn-more">
          <Link to="/finance-info">How much can I afford?</Link>
        </p>
      </section>

      {/* Promotion Banner */}
      <section className="promotion-banner">
        <h2>Brand new cars found</h2>
        <p>Available in every brand.</p>
      </section>

      {/* Popular Brands Section */}
      <section className="popular-brands">
        <h2>View deals from popular brands</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/audi-logo.jpg')} alt="Audi Logo" />
            <span>Audi</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/bmw-logo.jpg')} alt="BMW Logo" />
                <span>BMW</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/honda-logo.jpg')} alt="Honda Logo" />
                <span>Honda</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/kia-logo.jpg')} alt="Kia Logo" />
                <span>Kia</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Award-winning Cars Section */}
      <section className="award-winning">
        <h2>Award winning cars. As voted by you.</h2>
        <p>See the most popular models that received top awards.</p>
        <Link to="/awards" className="cta-button">Read the awards</Link>
      </section>

      {/* Pre-Haggled Prices Section */}
      <section className="pre-haggled-prices">
        <div className="price-info">
          <h3>Pre-haggled prices just for you</h3>
          <p>Transparent, competitive prices with no hidden fees.</p>
          <Link to="/pricing" className="cta-button">Learn more</Link>
        </div>
        <div className="price-info">
          <h3>Average Saving</h3>
          <p>£3,042 on new cars</p>
          <Link to="/savings" className="cta-button">Find out more</Link>
        </div>
      </section>

      {/* Stock Availability Section */}
      <section className="stock-availability">
        <div className="stock-info">
          <h2>20,560 vehicles available</h2>
          <p>Find your perfect car from our wide range of stock.</p>
          <Link to="/vehicles" className="cta-button">Browse vehicles</Link>
        </div>
        <div className="stock-info">
          <h2>In-stock and available now</h2>
          <p>New cars ready for immediate delivery.</p>
          <Link to="/stock" className="cta-button">View stock</Link>
        </div>
      </section>

      {/* Electric Vehicles Section */}
      <section className="ev-info">
        <h2>Ready to go electric?</h2>
        <p>Discover our range of electric vehicles and hybrids.</p>
        <Link to="/electric-vehicles" className="cta-button">Browse EV vehicles</Link>
      </section>

      {/* Car Leasing Section */}
      <section className="leasing-section">
        <h2>Discover car leasing</h2>
        <p>Explore competitive leasing options for brand new cars.</p>
        <Link to="/leasing" className="cta-button">See leasing deals</Link>
      </section>

      {/* Benefits Section */}
      <section className="new-car-benefits">
        <h2>New cars come with great benefits</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <h3>Cutting-edge technology</h3>
            <p>Enjoy the latest in safety and entertainment features.</p>
          </div>
          <div className="benefit-card">
            <h3>Manufacturer warranty</h3>
            <p>Peace of mind with warranties direct from the manufacturer.</p>
          </div>
          <div className="benefit-card">
            <h3>Flexible finance options</h3>
            <p>Explore tailored finance plans to suit your needs.</p>
          </div>
        </div>
      </section>

      {/* Latest Reviews Section */}
      <section className="latest-reviews">
        <h2>Latest reviews by our experts</h2>
        <div className="reviews-grid">
          <div className="review-card">Review 1</div>
          <div className="review-card">Review 2</div>
          <div className="review-card">Review 3</div>
          <div className="review-card">Review 4</div>
        </div>
        <Link to="/reviews" className="view-more-reviews">See more reviews</Link>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Send me great Vanir Motors offers and the latest vehicle reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default NewCars;
