import React, { useEffect, useState } from 'react';
import axios from 'axios';

function VehicleList() {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    axios.get('/api/vehicles')
      .then(response => setVehicles(response.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <div>
      {vehicles.map(vehicle => (
        <div key={vehicle._id}>
          <h2>{vehicle.make} {vehicle.model}</h2>
          <p>Price: {vehicle.price}</p>
          {/* Add more details as needed */}
        </div>
      ))}
    </div>
  );
}

export default VehicleList;