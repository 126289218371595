import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './VehicleSearch.css';

const VehicleSearch = ({ searchType }) => {
    const [vehicles, setVehicles] = useState([]);
    const [filters, setFilters] = useState({
        make: '',
        model: '',
        year: ''
    });

    useEffect(() => {
        const fetchVehicles = async () => {
            try {
                const queryString = Object.keys(filters)
                    .filter(key => filters[key])
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
                    .join('&');

                const response = await axios.get(`https://vanirsales.com/api/vehicles/search/${searchType}?${queryString}`);
                console.log('API response:', response.data);
                setVehicles(response.data);
            } catch (error) {
                console.error('Error fetching vehicles:', error);
            }
        };

        fetchVehicles();
    }, [filters, searchType]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    return (
        <div className="vehicle-search">
            <div className="filters">
                <input type="text" name="make" placeholder="Make" onChange={handleFilterChange} />
                <input type="text" name="model" placeholder="Model" onChange={handleFilterChange} />
                <input type="text" name="year" placeholder="Year" onChange={handleFilterChange} />
            </div>
            <div className="results">
                {Array.isArray(vehicles) && vehicles.length > 0 ? (
                    vehicles.map(vehicle => (
                        <Link key={vehicle._id} to={`/vehicles/${vehicle._id}`} className="vehicle-card">
                            <img src={vehicle.media?.images[0] || 'placeholder.jpg'} alt={`${vehicle.vehicle.make} ${vehicle.vehicle.model}`} />
                            <div className="vehicle-info">
                                <h3>{vehicle.vehicle.make} {vehicle.vehicle.model}</h3>
                                <p>Year: {vehicle.vehicle.yearOfManufacture || 'N/A'}</p>
                                <p>Price: £{vehicle.adverts.retailAdverts.totalPrice.amountGBP || 'N/A'}</p>
                            </div>
                        </Link>
                    ))
                ) : (
                    <p>No vehicles found</p>
                )}
            </div>
        </div>
    );
};

export default VehicleSearch;
