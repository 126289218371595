import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NewVans.css';

function NewVans() {
  const [showAllBrands, setShowAllBrands] = useState(false); // State to manage brand visibility

  const toggleShowBrands = () => {
    setShowAllBrands(!showAllBrands); // Toggle visibility
  };
  const [faq, setFaq] = useState([
    { question: "What is the best new van to buy?", answer: "The best new van depends on your specific needs. For cargo capacity and durability, the Ford Transit and Mercedes-Benz Sprinter are popular choices due to their spacious interiors and reliability. For city driving, the Ford Transit Connect and Volkswagen Caddy offer compact design and efficient fuel economy. If fuel efficiency is a priority, consider hybrid or electric options like the Mercedes-Benz eVito or the Peugeot e-Expert. Look for features like payload capacity, fuel economy, tech features, and safety ratings when choosing the best van for you.", isOpen: false },
    { question: "Can I buy a new van on finance?", answer: "Yes, buying a new van on finance is a common option. Financing methods include Hire Purchase (HP), where you pay monthly installments until you own the van, or Personal Contract Purchase (PCP), which offers lower payments and the option to purchase or return the van at the end. Other options include leasing or business contract hire if the van is for business use. Each financing option has different requirements and benefits, so reviewing them with the dealership or lender can help determine which fits your budget and needs.", isOpen: false },
    { question: "How do I know I can afford it?", answer: "To determine if you can afford a new van, evaluate your budget, including monthly income, expenses, and savings. Financial experts generally suggest that vehicle expenses (including insurance, fuel, and maintenance) should not exceed 15-20% of your monthly income. Use a financing calculator to estimate monthly payments based on the van’s price, interest rate, and term. Additionally, factor in insurance, tax, and maintenance costs. It’s wise to leave room in your budget for unexpected expenses.", isOpen: false },
    { question: "How do I compare new van models?", answer: "To compare new van models, start by listing your priorities, such as payload capacity, fuel efficiency, tech features, and price. Use online comparison tools on automotive websites to view specs side-by-side, including engine options, interior space, and safety ratings. Test driving models you’re interested in can provide firsthand experience with comfort and handling. Additionally, consider total ownership costs like fuel consumption, insurance rates, and anticipated maintenance, which can vary across models.", isOpen: false },
    // Add more FAQs here as needed
  ]);

  const toggleFaq = (index) => {
    setFaq(faq.map((item, i) => (i === index ? { ...item, isOpen: !item.isOpen } : item)));
  };
  return (
    <div className="new-vans-page">
      {/* Hero Section */}
      <section className="vans-hero">
        <h2>Brand New Vans</h2>
        <p>Drive your new van away today</p>
        <form className="vans-search-form">
          <select>
            <option>Make</option>
            <option>Ford</option>
            <option>Volkswagen</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Model</option>
            <option>Transit</option>
            <option>Transporter</option>
            {/* Add more options */}
          </select>
          <button className="cta-button">Search new vans</button>
        </form>
      </section>

      {/* Browse by Brand Section */}
      <section className="browse-brands">
        <h2>Browse vans by brand</h2>
        <div className="brand-grid">
          <div className="brand-logo">
            <img src={require('../assets/logos/ford-logo.jpg')} alt="Ford Logo" />
            <span>Ford</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/volkswagen-logo.jpg')} alt="Volkswagen Logo" />
            <span>Volkswagen</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/mercedes-logo.jpg')} alt="Mercedes-Benz Logo" />
            <span>Mercedes-Benz</span>
          </div>
          <div className="brand-logo">
            <img src={require('../assets/logos/renault-logo.jpg')} alt="Renault Logo" />
            <span>Renault</span>
          </div>
          {showAllBrands && (
            <>
              {/* Additional brands */}
              <div className="brand-logo">
                <img src={require('../assets/logos/citroen-logo.jpg')} alt="Citroen Logo" />
                <span>Citroen</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/fiat-logo.jpg')} alt="Fiat Logo" />
                <span>Fiat</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/iveco-logo.jpg')} alt="Iveco Logo" />
                <span>Iveco</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/man-logo.jpg')} alt="MAN Logo" />
                <span>MAN</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/maxus-logo.jpg')} alt="Maxus Logo" />
                <span>Maxus</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/nissan-logo.jpg')} alt="Nissan Logo" />
                <span>Nissan</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/peugeot-logo.jpg')} alt="Peugeot Logo" />
                <span>Peugeot</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/toyota-logo.jpg')} alt="Toyota Logo" />
                <span>Toyota</span>
              </div>
              <div className="brand-logo">
                <img src={require('../assets/logos/vauxhall-logo.jpg')} alt="Vauxhall Logo" />
                <span>Vauxhall</span>
              </div>
            </>
          )}
        </div>
        <button onClick={toggleShowBrands} className="view-all-brands-btn">
          {showAllBrands ? 'Hide all brands' : 'Show all brands'}
        </button>
      </section>

      {/* Find a Van Section */}
      <section className="find-a-van">
        <h2>Find a van that suits you</h2>
        <div className="van-categories">
          <div className="van-category">Panel Vans</div>
          <div className="van-category">Combi Vans</div>
          <div className="van-category">Camper Vans</div>
          <div className="van-category">Electric Vans</div>
        </div>
      </section>

      {/* Popular Models Section */}
      <section className="popular-vans">
        <h2>Find deals on popular models</h2>
        <div className="model-grid">
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/ford-transit.jpg')} alt="Ford Transit" className="model-image" />
            <span className="model-title">Ford Transit</span>
          </div>
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/volkswagen-crafter.jpg')} alt="Volkswagen Crafter" className="model-image" />
            <span className="model-title">Volkswagen Crafter</span>
          </div>
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/mercedes-sprinter.jpg')} alt="Mercedes-Benz Sprinter" className="model-image" />
            <span className="model-title">Mercedes-Benz Sprinter</span>
          </div>
          <div className="model-card">
            <img src={require('../assets/vehicle-images/vans/renault-master.jpg')} alt="Renault Master" className="model-image" />
            <span className="model-title">Renault Master</span>
          </div>
        </div>
      </section>

      {/* New Vans Benefits Section */}
      <section className="new-van-benefits">
        <h2>New vans come with great benefits</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <h3>Manufacturer Warranty</h3>
            <p>Enjoy peace of mind with warranties direct from the manufacturer.</p>
          </div>
          <div className="benefit-card">
            <h3>Flexible Finance Options</h3>
            <p>Explore tailored finance plans to suit your needs.</p>
          </div>
          <div className="benefit-card">
            <h3>Latest Technology</h3>
            <p>Enjoy advanced safety and entertainment features.</p>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="van-reviews">
        <h2>Need a second opinion?</h2>
        <div className="reviews-grid">
          <div className="review-card">Review 1</div>
          <div className="review-card">Review 2</div>
          <div className="review-card">Review 3</div>
        </div>
        <Link to="/reviews" className="view-more-reviews">See more reviews</Link>
      </section>

      {/* FAQ Section */}
      <section className="faq">
        <h2>Your questions answered</h2>
        {faq.map((item, index) => (
          <div
            key={index}
            className="faq-item"
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              <span className="question-text">{item.question}</span>
              <span className={`faq-arrow ${item.isOpen ? 'open' : ''}`}></span>
            </div>
            {item.isOpen && <div className="faq-answer">{item.answer}</div>}
          </div>
        ))}
      </section>

      {/* Newsletter Section */}
      <section className="newsletter">
        <h2>Get the latest Vanir Motors offers and van reviews.</h2>
        <form className="newsletter-form">
          <input type="email" placeholder="Enter your email" />
          <button className="cta-button">Sign up</button>
        </form>
      </section>
    </div>
  );
}

export default NewVans;
