import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import VehicleList from './components/VehicleList';
import VehicleDetail from './components/VehicleDetail';
import ContactPage from './components/ContactPage';
import AboutPage from './components/AboutPage';
import Cars from './components/Cars';
import Vans from './components/Vans';
import Trucks from './components/Trucks';
import NewTrucks from './components/NewTrucks';
import UsedTrucks from './components/UsedTrucks';
import CarLeasing from './components/CarLeasing';
import CarFinance from './components/CarFinance';
import NewCars from './components/NewCars';
import UsedCars from './components/UsedCars';
import UsedVans from './components/UsedVans';
import NewVans from './components/NewVans';
import VanFinance from './components/VanFinance';
import VanLeasing from './components/VanLeasing';
import Cookies from './components/Cookies';
import Complaints from './components/Complaints';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Tcf from './components/Tcf';
import VehicleSearch from './components/VehicleSearch'; // Import the search component

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Core Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/complaints" element={<Complaints />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/tcf" element={<Tcf />} />

        {/* Vehicle-Specific Routes */}
        <Route path="/vehicles" element={<VehicleList />} />
        <Route path="/vehicles/:id" element={<VehicleDetail />} />

        {/* Cars Section */}
        <Route path="/cars/*" element={<Cars />} />
        <Route path="/cars/leasing" element={<CarLeasing />} />
        <Route path="/cars/finance" element={<CarFinance />} />
        <Route path="/cars/new" element={<NewCars />} />
        <Route path="/cars/used" element={<UsedCars />} />

        {/* Vans Section */}
        <Route path="/vans/*" element={<Vans />} />
        <Route path="/vans/used" element={<UsedVans />} />
        <Route path="/vans/new" element={<NewVans />} />
        <Route path="/vans/finance" element={<VanFinance />} />
        <Route path="/vans/leasing" element={<VanLeasing />} />

        {/* Trucks Section */}
        <Route path="/trucks/*" element={<Trucks />} />
        <Route path="/trucks/new" element={<NewTrucks />} />
        <Route path="/trucks/used" element={<UsedTrucks />} />

        {/* Search Functionality for Vehicle Types */}
        <Route path="/car-search" element={<VehicleSearch searchType="car" />} />
        <Route path="/van-search" element={<VehicleSearch searchType="van" />} />
        <Route path="/truck-search" element={<VehicleSearch searchType="truck" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;